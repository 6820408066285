import React from "react";
import { Link } from "gatsby";
import SEO from "../components/seo";
import styled from "styled-components";
import {
  mainWhite,
  mainBlack,
  mainPink,
  mainGreen,
  screen,
  innerWidth,
} from "../components/common/variables";
import imgLargeBg from "../images/peddlin-cycling-404.jpg";
import imgSmallBg from "../images/peddlin-bike-riding-404.jpg";
import { ScrollLocky } from "react-scroll-locky";

const Wrapper = styled.div`
  background-color: ${mainWhite};
  background-image: url(${imgSmallBg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  height: ${(props) =>
    props.browserHeight ? `${props.browserHeight}px` : `100vh`};
  @media ${screen.xsmall} {
    height: 100vh;
    background-image: url(${imgLargeBg});
  }

  #text-404 {
    color: ${mainBlack};
    position: absolute;
    top: 130px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%;
    padding: 0 30px;
    text-align: center;
    @media ${screen.xsmall} {
      max-width: ${innerWidth};
      padding: 0 70px;
      top: calc(50% + 42px);
      transform: translate(-50%, -50%);
      text-align: left;
      top: 50%;
    }

    @media ${screen.large} {
      padding: 0;
    }
    h3 {
      font-size: 2.3rem;
      font-weight: 500;
      margin-top: 6px;
      line-height: 1;
      @media ${screen.xsmall} {
        font-size: 3.6rem;
        margin-top: 26px;
      }
      @media ${screen.medium} {
        font-size: 4.5rem;
      }
    }

    p {
      font-size: 1.2rem;
      font-weight: 500;
      display: block;
      line-height: 1.2;
      margin-top: 10px;
      @media ${screen.xsmall} {
        font-size: 1.7rem;
        margin-top: 18px;
      }
      @media ${screen.medium} {
        font-size: 2.1rem;
      }
    }

    a {
      background: ${mainPink};
      color: ${mainWhite};
      font-size: 1.15rem;
      padding: 12px 30px;
      border-radius: 30px;
      line-height: 1.5;
      display: inline-block;
      margin-top: 20px;
      transition: background 0.19s ease-out;
      width: 100%;
      @media ${screen.xsmall} {
        width: auto;
        font-size: 1rem;
        margin-top: 30px;
      }
      @media ${screen.medium} {
        font-size: 1.15rem;
      }
      @media ${screen.withCursor} {
        &:hover {
          background: ${mainGreen};
        }
      }
    }
  }
`;

const NotFoundPage = ({ browserHeight, browserWidth }) => {
  const isDesktop = browserWidth > 1240;

  return (
    <>
      <SEO title="404: Page Not found" />
      <ScrollLocky enabled={!isDesktop} isolation={false}>
        <Wrapper browserHeight={browserHeight}>
          <div id="text-404">
            <h3>
              Looks like you've
              <br /> taken a tumble
            </h3>
            <p>
              The page you're looking for
              <br />
              seems to have fallen over.
            </p>

            <Link to="/">TAKE ME HOME</Link>
          </div>
        </Wrapper>
      </ScrollLocky>
    </>
  );
};

export default NotFoundPage;
